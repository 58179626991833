.data-table {
    border-radius: 4px !important;
    box-shadow: none !important;

    thead {
        width: 100%;
        background-color: #EBEBF4;
        display: revert;
        border-bottom: 2px solid var(--secondary----grey);
    }

    th {
        color: var(--primary----grey); 
        .b-right {
            border-right: 1px solid var(--secondary----grey);
        }             
    }     

    tr:nth-child(2n){
        background-color: #F9F9FA;
    }
    .table-sorting-wrapper {
        display: inline-block;
        vertical-align: inherit;
        margin-left: 8px;
        cursor: pointer;
        img {
            display: block;
            &:first-child {
                margin-bottom: 3px;
            }
            &:hover {
                filter: brightness(80%);
                transition: all 0.1s ease;
            }
        }
    }
    .social-link {
        background: var(--hover----grey);
        border-radius: 4px;
        width: 26px;
        height: 26px;
        display: flex;
        justify-content: center;
        flex-direction: row;
        align-items: center;
        text-align: center;
        cursor: pointer;
        border: 1px solid var(--secondary----grey);

        &:hover {
            background: var(--background----grey);
            transition: all 0.1s ease;            
        }
        a {
            width: 100%;
        }
    }

    .whitelist-text {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        padding: 8px 12px;
        gap: 4px;
        border: 1px solid var(--focus----blue);
        color: var(--brand-color---5);
        border-radius: 4px;
        font-weight: 500;
        font-size: 14px;
        img {
            width: 12px;
            height: 12px;
        }
    }

    .blacklist-text {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        padding: 8px 12px;
        gap: 4px;
        color: var(--red-alert---hard);
        border: 1px solid var(--red-alert---ligth-2);
        border-radius: 4px;
        font-weight: 500;
        font-size: 14px;
        img {
            width: 12px;
            height: 12px;
        }
    }

}

.table-pagination {
    margin-top: 1em;
    display: flex;
    align-items: center;
    justify-content: center;


    .Mui-selected {
        background: #7EC1CC !important;
        border: 0;
        color: #fff;
    }
}