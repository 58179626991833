.card-contact-container {
    padding: 2%;
    padding-top: 3%;
    padding-bottom: 2%;
    margin: 2% 0;
    align-items: baseline;
    align-content: center;
    max-width: 100%;
    border-radius: 4px !important;
    box-shadow: none;

    &:hover {
        box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.12);
    }

    .avatar-image {
        border-radius: 100%;
        margin-top: 0;
    }

    .medal-image {
        position: absolute;
        left: 55px;
        border-radius: 50px;
        max-width: 20px;
        background: #AE2577;
    }

    .scoring-container {
        border: 2px solid #EBEBF4;
        background-color: #fff;
        border-radius: 100px;
        color: var(--primary----grey);
        font-weight: 600;
        font-size: 15px;
        line-height: 21px;
        width: 90px;
        height: 30px;
        display: inline-flex;
        justify-content: center;
        align-items: center;
        margin-right: 4%;

        img {
            margin-right: 0.5em;
        }
    }

    .interactions-container {
        border: 2px solid #EBEBF4;
        background-color: #fff;
        border-radius: 100px;
        font-weight: 600;
        font-size: 15px;
        line-height: 21px;
        width: 90px;
        height: 30px;
        display: inline-flex;
        justify-content: center;
        align-items: center;
        margin-right: 4%;

        img {
            margin-right: 0.5em;
            height: 12px;
            width: 12px;
        }
    }

    .add-favourite {
        margin-right: 1%;
        border: 1px solid #EBEBF4;
        background: #fff;
        padding: 0; 
        z-index: 1;
        height: 30px;
        min-width: 40px !important; 
    }

    .MuiTypography-subtitle1 {
        color: var(--subtitle----grey);
        font-size: 14px;
        line-height: 21px;
        font-weight: 500;
    }

    .MuiTypography-h5 {
        color: #252B42;
        font-weight: 600;
        font-size: 16px;
        line-height: 26px;
    }

    a {
        color: var(--primary----grey);
        background: #F9F9FA;
        padding: 0.4em 1.5em;
        font-weight: 500;
        font-size: 15px;
        line-height: 21px;
        border: 1px solid var(--secondary----grey);
        border-radius: 4px;
        display: flex;
        justify-content: center;
        width: 100%;
        
        img {
            &:hover {
                filter: invert(14%) sepia(35%) saturate(708%) hue-rotate(189deg) brightness(93%) contrast(91%);
            }
        }

        &:hover {
            background: #EBEBF4;
            border: 1px solid var(--secondary----grey);
            color: var(--primary----black);
        }
    }

    .MuiCardActions-spacing {
        min-height: 50px;
    }

    .profile-link {
        background-color: #fff;
        font-weight: 600;
        font-size: 17px;
        padding: 0;
        border: 0;
        display: block;
        margin-bottom: 0.5em;
        &:hover {
            background-color: #fff;
            border: 0;
            color: var(--primary----black) !important;
            text-decoration: underline !important;
        }
    }
}


@media (max-width: 1250px) {
    .card-contact-container {
        a {
            padding: 0.4em 0.8em;
        }
    }

}