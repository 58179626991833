.group-filters-dropdown {
  gap: 4px;
  background: transparent !important;
  border: none !important;
  cursor: pointer;
  height: 60px;

  border-radius: 0 !important;

  .ant-space-item {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    max-width: 230px;
    color: var(--gray---1);
    font-family: "Poppins";
    font-weight: 400;
  }

  &:hover {
    color: var(--gray---1);
    font-family: "Poppins";
    font-weight: 400;
    background: var(--gray---6) !important;
    border: none !important;
  }

  &:focus {
    color: var(--gray---1);
    font-family: "Poppins";
    font-weight: 400;
    border: none !important;
  }
}

.ant-dropdown-menu-item {
  flex-direction: row-reverse;
  justify-content: flex-end;
  .ant-dropdown-menu-title-content {
    
    margin-left: 0;
    margin-right: 10px;
    flex: none !important;
  }
}
