input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}


hr {
  border-left: 2px solid #EBEBF4;
  height: 100% !important;
  width: 1px;
}

.MuiFormControlLabel-root {
  .MuiFormControlLabel-label {
    color: #85879C !important;
    font-weight: 500 !important;
  }
}

.MuiSlider-root {
    border-radius: 23.3582px;
    padding: 0;


    .MuiSlider-thumb {
        background: #2EA0B2;
        border: 0;
    }

  }
  .Mui-checked {
    color: #2EA0B2 !important 
}
