.card-vip-detail {
  background-image: linear-gradient(
    180deg,
    rgba(197, 154, 22, 0.2) 0%,
    rgba(197, 154, 22, 0) 4em
  );

  .tabs-container {
    .MuiTabs-root {
      background-color: #ebebf4;
      padding: 0;
      border-radius: 3px;
      .Mui-selected {
        background-color: #85879c;
        color: #fff !important;
      }
    }
  }

  .menutabs-container {
    .MuiTabs-root {
      padding: 0;
      background-color: #fff;
      border: 0;
      border-radius: 0;
      button {
        background-color: #fff;
        padding: 0.4em 1em;
      }
      .Mui-selected {
        color: #585a6a !important;
        font-weight: bold;
        border-radius: 4px;
        border-bottom: 4px solid #585a6a;
      }
    }
  }
}
.social-icons {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  .separator {
    width: 1px;
    height: 32px;
    background-color: #ebebf4;
    margin: 0px 8px;
  }
  div {
    padding: 0px;
    min-height: 0px !important;
  }
  .save-contact-badge-compact {
    display: inline-block;
    min-width: 32px;
    height: 32px;
    .icon-wrapper {
      display: inline-flex;
      align-items: center;
      border: 1px solid #ebebf4;
      border-radius: 6px;
      width: 32px;
      height: 32px;
      cursor: pointer;
      margin: 0 auto;
      img {
        margin: 0 auto;
      }
    }
  }
}

.card-contact-detail {
  background-image: linear-gradient(
    180deg,
    rgba(174, 37, 119, 0.15) 0%,
    rgba(174, 37, 119, 0) 4em
  );

  .menutabs-container {
    .MuiTabs-root {
      padding: 0;
      background-color: #fff;
      border: 0;
      border-radius: 0;
      button {
        background-color: #fff;
        padding: 0.4em 1em;
      }
      .Mui-selected {
        color: #585a6a !important;
        font-weight: bold;
        border-radius: 4px;
        border-bottom: 4px solid #585a6a;
      }
    }
  }
}

.card-vip-container {
  padding-top: 3%;
  padding-bottom: 2%;
  align-items: baseline;
  align-content: center;
  background-color: transparent !important;
  border: 0 !important;
  box-shadow: none !important;

  .avatar-vip-image {
    margin: auto;
    border: 3px solid #a98413;
    border-radius: 100%;
    background: #faf0d1;
  }

  .avatar-contact-image {
    margin: auto;
    border: 3px solid #ae2577;
    border-radius: 100%;
    background: #fbeaf4;
  }

  .medal-image {
    position: absolute;
    top: 0;
    right: -3%;
    border-radius: 50px;
    background: #a98413;
  }

  .contact-image {
    position: absolute;
    top: 0;
    right: -3%;
    border-radius: 50px;
    width: 35px;
  }

  .saved-contact {
    position: absolute;
    top: -10px;
    right: 20%;
    width: 30px;
    height: 30px;
  }

  .MuiTypography-subtitle1 {
    color: var(--subtitle----grey);
    font-size: 14px;
    line-height: 21px;
    font-weight: 500;
  }

  .MuiTypography-h1 {
    color: #252b42;
    font-weight: 600;
    font-size: 24px;
    line-height: 26px;
  }

  .social-icons {
    a {
      background: #f9f9fa;
      padding: 0 0.4em;
      border: 1px solid var(--secondary----grey);
      border-radius: 4px;
      margin-left: 5px;

      &:hover {
        background-color: var(--background----grey);
        img {
          filter: invert(14%) sepia(35%) saturate(708%) hue-rotate(189deg)
            brightness(93%) contrast(91%);
        }
      }
    }
  }

  .MuiCardActions-spacing {
    min-height: 50px;
  }

  .contacts-number {
    width: fit-content;
    text-align: center;
    border-radius: 15px;
    padding: 6px 12px;
    font-weight: 500;
    color: var(--primary----grey);
    img {
      height: fit-content;
      margin: auto;
      width: 16px;
    }
  }

  .saved-contacts-number {
    width: fit-content;
    text-align: center;
    border-radius: 15px;
    padding: 6px 12px;
    font-weight: 500;
    color: var(--primary----grey);
    img {
      height: fit-content;
      margin: auto;
    }
  }
}

.save-contact-container {
  position: absolute;
  right: 0;

  button:hover {
    background-color: #fff;
    border: 1px solid var(--primary----black);
    color: var(--primary----black);
  }

  button:active {
    background-color: #c7e6eb;
    color: #2ea0b2;
  }

  .save-button {
    background: #2ea0b2;
    color: #fff;
    font-weight: 500;
  }

  .save-button:focus:not(:hover) {
    color: #fff;
  }

  .remove-button {
    border: 1px solid #eb5757;
    background: #fff;
    color: #eb5757;
    font-weight: 500;
  }

  .remove-button:focus:not(:hover) {
    color: #eb5757;
  }
}

.filters-dropdown {
  padding: 3px 12px !important;
  gap: 4px;
  background: #fff !important;
  border: 1px solid var(--secondary----grey);
  border-radius: 4px !important;
  cursor: pointer;

  .ant-space-item {
    color: var(--primary----black);
    font-family: "Poppins";
    font-weight: 500;
  }

  &:hover {
    color: var(--primary----black);
    font-family: "Poppins";
    font-weight: 500;
    background: var(--hover----grey) !important;
    border: 1px solid var(--secondary----grey) !important;
  }

  &:focus {
    color: var(--primary----black);
    font-family: "Poppins";
    font-weight: 500;
    border: 1px solid var(--focus----blue) !important;
  }

  &.back-transparent {
    background: transparent !important;
    border: 0 !important;
  }
}

.ant-dropdown-menu {
  
  .ant-dropdown-menu-title-content {
    width: 100%;
    text-align: left;
  }
  .action-button {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    color: var(--primary----grey);
    text-transform: none;
    padding: 0;
    z-index: 1;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-start;
  }

  .action-button:hover {
    color: var(--primary----black);

    img {
      filter: invert(11%) sepia(29%) saturate(1185%) hue-rotate(190deg)
        brightness(93%) contrast(85%);
    }
  }
}
