.dictionary {
  height: 100%;

  .title {
    font-size: 18px;
    font-weight: 700;
    line-height: 28px;
  }

  p {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    display: flex;
    margin: 0;
  }

  .table-pagination {
    margin-top: 0.5em !important;
    height: 100%;

    .title {
      font-size: 18px;
      font-weight: 700;
      line-height: 28px;
    }

    p {
      font-family: 'Poppins';
      font-style: normal;
      margin-bottom: 20px;
    }

    .lateral-tools {
      display: flex;
      flex-direction: column;
      gap: 20px;
    }

    .content {
      padding-top: 30px;

      .nothing {
        margin: 0 auto;
        text-align: center;
        max-width: 342px;
        padding-top: 30vh;
        padding-bottom: 30vh;

        img {
          margin: 0 auto;
        }

        .noKeywordCreated {
          font-weight: 600;
          font-size: 16px;
          line-height: 22px;
        }

        .noKeywordCreatedSub {
          font-weight: 400;
          font-size: 16px;
          line-height: 24px;
          display: flex;
          margin: 0;
        }

        .table-pagination {
          margin-top: 0.5em !important;
        }
      }

    }
  }
}

.content {
  background-color: #f9f9fa;

  .list {
    background-color: #ffffff;

    .nothing {
      margin: 0 auto;
      text-align: center;
      max-width: 342px;
      padding-top: 30vh;
      padding-bottom: 30vh;

      img {
        margin: 0 auto;
      }

      .noKeywordCreated {
        font-weight: 600;
        font-size: 16px;
        line-height: 22px;
      }

      .noKeywordCreatedSub {
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
      }
    }

    .keywordList {
      border: 1px solid var(--background----grey);
      border-radius: 4px;
      padding-top: 5px;

      .kwlLabel {
        font-weight: 600;
        font-size: 18px;
        font-weight: 700;
        line-height: 28px;
    }
    
    .table-pagination {
        margin-top: 0.5em !important;
        line-height: 26px;
        color: var(--subtitle----grey);
      }

      height: 100%;

      .title {
        font-size: 18px;
        font-weight: 700;
        line-height: 28px;
        margin-bottom: 20px;
      }

      .lateral-tools {
        display: flex;
        flex-direction: column;
        gap: 20px;
      }

      .content {
        padding-top: 30px;

        .nothing {
          margin: 0 auto;
          text-align: center;
          max-width: 342px;
          padding-top: 30vh;

          img {
            margin: 0 auto;
          }

          .noKeywordCreated {
            font-weight: 600;
            font-size: 16px;
            line-height: 22px;
          }

          .noKeywordCreatedSub {
            font-weight: 400;
            font-size: 16px;
            line-height: 24px;
          }
        }

      }

      .table-pagination {
        margin-top: 0.5em !important;
      }
    }

    .keywordList {
      border: 1px solid var(--background----grey);
      border-radius: 4px;
      padding-top: 5px;

      .keywordListHeader {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        padding: 0px 15px;
        padding-top: 10px;
        // border: 1px solid var(--background----grey);
        border-radius: 4px;
        padding-top: 5px;

        .kwlLabel {
          font-weight: 600;
          font-size: 18px;
          line-height: 26px;
          color: var(--gray---2);
        }

        .MuiBox-root {
          height: 32px;
        }
      }

      color: var(--subtitle----grey);
    }
}

.Toastify {
  .Toastify__toast-container {
    width: 400px;

      .Toastify__toast--success {
        background: #eafcfa;

        .Toastify__toast-icon {
          margin-right: 0.8em;
        }

        p {
          font-size: 14px;
          color: var(--primary----black);
          font-weight: 600;
          margin-bottom: -10px;
        }

        .Toastify__progress-bar--success {
          background: #18b19d;
        }
      }
    }
  }

  .space {
    display: block;
    min-height: 350px;
    min-width: 315px;
    border: 1px solid var(--black);
  }

  .footer {
    position: absolute;
    display: none;
    bottom: 0px;
    left: 0px;
    height: 64px;
    width: 100%;
    text-align: right;

    button {
      border-radius: 4px;
      border-width: 0px;
      padding: 8px 16px;
      font-weight: 500;
      font-size: 14px;
      line-height: 21px;
      margin: 4px;

      &.secondaryBtn {
        background-color: #ebebf4;
        color: #585a6a;
      }

      &.primaryBtn {
        background-color: #2ea0b2;
        color: #ffffff;

        &:disabled {
          background: #e6f4f6;
          color: #7ec1cc;
        }
      }
    }
  }

  .toast {
    font-size: 14px !important;
  }

  .modal {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 400;
    background: #ffffff;
    box-shadow: 1px 1px 20px rgba(64, 64, 68, 0.5);
    border-radius: 8px;
    outline: none;
    width: 440px;
    height: 317px;
    text-align: center;
    padding: 36px 70px;

    img {
      margin-bottom: 16px;
      margin-top: 14px;

      &[alt="close"] {
        cursor: pointer;
        position: absolute;
        top: 16px;
        right: 16px;
        margin: 0px;
      }
    }

    h1 {
      font-weight: 600;
      font-size: 16px !important;
      margin: 0px;
      margin-bottom: 20px;
    }

    h2 {
      font-weight: 500;
      font-size: 14px;
      line-height: 21px;
      margin-bottom: 16px;
    }

    button {
      border-radius: 4px;
      border-width: 0px;
      padding: 8px 16px;
      font-weight: 500;
      font-size: 14px;
      line-height: 21px;
      margin: 4px;

      &.secondaryBtn {
        background-color: #ebebf4;
        color: #585a6a;
      }

      &.primaryBtn {
        background-color: #2ea0b2;
        color: #ffffff;
      }
    }
  }
}