.addVip-card {
    width: 100%;
    border: 1px solid var(--background----grey);
    border-radius: 4px;

    .ant-card-head {
        height: 48px;
        box-shadow: var(--card---shadow);
    }
    .ant-card-head-title {
        font-weight: 600;
        font-size: 12px;
        padding: 15px 0 !important;
        text-transform: uppercase;
        color: var(--primary----black);
        font-family: 'Poppins';
    }

    .ant-card-body {
        padding: 12px 15px;
        padding-bottom: 2em;
    }

    span {
        font-weight: 500;
        font-size: 13px;
        line-height: 18px;
        margin-bottom: 5px;
        color: var(--subtitle----grey);
        width: 100%;
        display: block;
        text-transform: uppercase;
    }

    .switch-container {
        align-items: center;
        .ant-switch {
            background-color: var( --error----red);
            &:hover {
                background-color: var(--error----red) !important;
            }
        }
        .ant-switch-checked {
            background-color: var(--primary----blue);
            &:hover {
                background-color: var(--primary----blue) !important;
            }
        }
        span {
            font-style: normal;
            font-weight: 500;
            font-size: 16px;
            line-height: 24px;
            color: var(--primary----grey);
            font-family: "Poppins";
            text-transform: none;
            margin-bottom: 0;
        }
    }
    
    hr {
        border-top: 1px solid var(--secondary----grey);
        width: 100%;
    }

    .ant-input {
        background: #FFFFFF;
        border: 1px solid var(--terciary---grey);
        border-radius: 4px;
    }

    .groups-dropdown {
        width: 100%;
        span {
            display: inline-flex;
            width: 90%;
            text-transform: none;
            overflow: hidden;
            font-weight: 500;
            font-size: 16px;
            line-height: 24px;        
        }
        
    }

    .error {
        font-family: 'Poppins';
        font-style: italic;
        font-weight: 400;
        font-size: 12px;
        line-height: 16px;
        color: var(--primary----grey);
        text-transform: none;
    }
    .error-input {
        border: 1px solid var(--error----red)
    }
}

.ant-dropdown-menu {
    li {
        &:hover {
            background-color: var(--background----grey) !important;
            border-radius: 2px;
        }
    }
}  