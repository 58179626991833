.cards-container {
  width: 100%;
  padding-top: 40px;
  padding-bottom: 40px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 15px;

  .dashboard-card {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    box-shadow: var(--card---shadow);
    border-radius: 4px;
    height: 100%;
    height: 128px;
    width: 312px; 
    padding-left: 25px;

    .dashboard-card-title {
      font-size: 36px;
      margin-bottom: 0;
      font-weight: 400;
      color: var(--black);
    }

    .dashboard-card-subtitle {
      font-size: 14px;
      font-weight: 500;
      color: var(--gray---2);
      margin-bottom: 0;
    }

    .dashboard-card-image {
      border-right: 1px solid var(--background----grey);
      padding-right: 12px;
      margin-right: 12px;
    }
  }
}

.cards-container-zoomed {
  width: 100%;
  padding-top: 40px;
  padding-bottom: 40px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 15px;

  .dashboard-card {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    box-shadow: var(--card---shadow);
    border-radius: 4px;
    height: 112px;
    width: 260px; 
    padding-left: 16px;

    .dashboard-card-title {
      font-size: 36px;
      margin-bottom: 0;
      font-weight: 400;
      color: var(--black);
    }

    .dashboard-card-subtitle {
      font-size: 14px;
      font-weight: 500;
      color: var(--gray---2);
      margin-bottom: 0;
    }

    .dashboard-card-image {
      border-right: 1px solid var(--background----grey);
      padding-right: 12px;
      margin-right: 12px;
    }
  }
}

.css-1rz42xj-cluster-legend-canvas{
  width: 100% !important;
}