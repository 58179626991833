/**
  * Core Styles
  */
h1 {
  font-size: 24px !important;
}

.contact-list {
  padding-top: 0 !important;
  margin-top: 1em !important;
  padding-right: 10px;
}

.contact-list::-webkit-scrollbar
{
  margin-left: 10px;
	border-radius: 10px;
	background-color: #F5F5F5;
  width: 10px;
  height: 8px;
}

.contact-list::-webkit-scrollbar-thumb {
  background: #C3C4CF;
  border-radius: 15px;
}

.unsave-modal img {
  display: flex;
  margin:auto;
}

.ml-auto {
  margin-left: auto !important;
}

.button-default {
  background-color: #fff !important;
  color: #535564 !important;
  text-transform: capitalize !important;
  font-weight: 500 !important;
  border: 1px solid #535564 !important;
}

.button-default:hover {
  background-color: var(--hover----grey) !important; 
}

.ml-2 {
  margin-left: 0.5em !important;
}

.mr-2 {
  margin-right: 0.5em !important;
}

.b-left-grey {
  margin-left: 10px;
}

/**
  * Ant Design Overrides
  */
button.ant-btn-default {
  background: #F9F9FA;
  border: 1px solid #EBEBF4;
  border-radius: 2px !important;
  color: #585A6A;
  &:hover, &:focus, &:active {
    background: #EBEBF4;
    border: 1px solid #B8B9C6 !important;
    color: #585A6A !important;
  }
}