.group-card {
  width: 100%;
  border-radius: 4px 4px 4px 4px;
  background-color: var(--white);
  .card-header {
    background-color: var(--white);
    border-bottom: 1px solid var(--gray---5);
    box-shadow: var(--card---shadow);
    height: 48px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    .card-title {
      font-family: "Poppins";
      font-style: normal;
      font-weight: 600;
      font-size: 12px;
      line-height: 18px;
      height: 18px;
      display: flex;
      align-items: center;
      text-align: center;
      letter-spacing: 0.02em;
      color: var(--black);
      margin: 0;
      text-transform: uppercase;
    }
    .info-icon {
      //cursor: none;
    }
  }
  .card-body {
    background: var(--white);
    box-shadow: var(--card---shadow);
    height: 266px;
    padding: 12px 20px;
    overflow-y: auto;
    .item {
      border-bottom: 1px solid var(--gray---5);
      height: 40px;
      padding: 8px 10px;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;

      &:hover {
        background-color: var(--gray---6);
        cursor: pointer;
      }
      &:focus,
      &.selected {
        background-color: var(--acent-5---ligth);
        mix-blend-mode: normal;
      }

        .item-text {
          font-family: "Poppins";
          font-style: normal;
          font-weight: 500;
          font-size: 14px;
          line-height: 21px;
          display: flex;
          align-items: center;
          color: var(--gray---1);
          display: block;
          max-width: 98%;
          white-space: nowrap;
          overflow: hidden !important;
          text-overflow: ellipsis;
        }
      
      .item-action {
        width: min-content;
        height: min-content;
        position: relative;
        display: flex;
        flex-direction: row;
        transition: all 0.5s ease-in-out;
        .item-icon {
          position: absolute;
          top: 0;
          left: 0;
          z-index: 1;
          width: 16px;
          height: 16px;
          margin: 6px 0px 13px 12px;
        }
        .item-badge {
          display: flex;
          flex-direction: row;
          align-items: flex-end;
          justify-content: flex-end;
          gap: 4px;
          width: 71px;
          height: 28px;
          padding-right: 4px;
          padding-bottom: 1px;
          color: var(--black);
          background: var(--white);
          border-radius: 16px;
          border: 2px solid var(--gray---5);
          .ant-badge-count {
            color: var(--gray---1);
            padding: 0;
            padding-top: 1px;
            margin-top: auto;
            margin-bottom: auto;
            margin-right: 8px;
            max-width: 27px;
            height: 20px;
            display: flex;
            align-items: baseline;
            justify-content: center;
            font-weight: 600;
            font-size: 14px;
            line-height: 20px;
          }
        }
        .edit {
          padding-left: 8px;
          margin-left: 8px;
          border-left: 1px solid var(--gray---4);
          display: flex;
          flex-direction: row;
          justify-content: flex-start;
          align-items: center;
          .edit-box {
            width: 24px;
            height: 24px;
            border: 1px solid var(--gray---4);
            border-radius: 4px;
            background-color: var(--white);
            margin: 2px;
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            &:hover, &:focus {
              border: 1px solid var(--gray---4);
              background-color: var(--gray---5);
            }
          }
        }
      }
    }
  }
  .card-bottom {
    background: var(--white);
    box-shadow: var(--card---shadow);
    border-top: 1px solid var(--gray---5);
    height: 50px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    padding: 20px 12px;
    .card-bottom-button {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: flex-start;
      width: fit-content;
      height: 32px;
      border-radius: 4px;
      padding: 4px 6px;
      border: 1px solid transparent;
      cursor: pointer;
      &:hover,
      &:focus {
        border: 1px solid var(--brand-color---3);
      }
      .footer-icon {
        width: 16px;
        height: 16px;
        //margin: 13px 0px 13px 16px;
      }
      .card-text {
        font-family: "Poppins";
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 21px;
        align-items: flex-end;
        letter-spacing: 0.02em;
        color: var(--brand-color---5);
        flex: none;
        order: 1;
        flex-grow: 0;
      }
    }
  }
}

.group-card-zoomed {
  width: 100%;
  border-radius: 4px 4px 4px 4px;
  background-color: var(--white);
  .card-header {
    background-color: var(--white);
    border-bottom: 1px solid var(--gray---5);
    box-shadow: var(--card---shadow);
    height: 48px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    .card-title {
      font-family: "Poppins";
      font-style: normal;
      font-weight: 600;
      font-size: 12px;
      line-height: 18px;
      height: 18px;
      display: flex;
      align-items: center;
      text-align: center;
      letter-spacing: 0.02em;
      color: var(--black);
      margin: 0;
      text-transform: uppercase;
    }
    .info-icon {
      //cursor: none;
    }
  }
  .card-body {
    background: var(--white);
    box-shadow: var(--card---shadow);
    height: 266px;
    padding: 12px 20px;
    overflow-y: auto;
    .item {
      border-bottom: 1px solid var(--gray---5);
      height: 40px;
      padding: 8px 10px;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;

      &:hover {
        background-color: var(--gray---6);
        cursor: pointer;
      }
      &:focus,
      &.selected {
        background-color: var(--acent-5---ligth);
        mix-blend-mode: normal;
      }

        .item-text {
          font-family: "Poppins";
          font-style: normal;
          font-weight: 500;
          font-size: 14px;
          line-height: 21px;
          display: flex;
          align-items: center;
          color: var(--gray---1);
          display: block;
          max-width: 98%;
          white-space: nowrap;
          overflow: hidden !important;
          text-overflow: ellipsis;
        }
      
      .item-action {
        width: min-content;
        height: min-content;
        position: relative;
        display: flex;
        flex-direction: row;
        transition: all 0.5s ease-in-out;
        .item-icon {
          position: absolute;
          top: 0;
          left: 0;
          z-index: 1;
          width: 16px;
          height: 16px;
          margin: 6px 0px 13px 12px;
        }
        .item-badge {
          display: flex;
          flex-direction: row;
          align-items: flex-end;
          justify-content: flex-end;
          gap: 4px;
          width: 100%;
          height: 28px;
          padding-right: 4px;
          padding-left: 4px;
          padding-bottom: 1px;
          color: var(--black);
          background: var(--white);
          border-radius: 16px;
          border: 2px solid var(--gray---5);
          .ant-badge-count {
            color: var(--gray---1);
            padding: 0;
            padding-top: 1px;
            margin-top: auto;
            margin-bottom: auto;
            max-width: 27px;
            height: 20px;
            display: flex;
            align-items: baseline;
            justify-content: center;
            font-weight: 600;
            font-size: 14px;
            line-height: 20px;
          }
        }
        .edit {
          padding-left: 4px;
          display: flex;
          flex-direction: row;
          justify-content: flex-start;
          align-items: center;
          .edit-box {
            width: 24px;
            height: 24px;
            border: 1px solid var(--gray---4);
            border-radius: 4px;
            background-color: var(--white);
            margin: 2px;
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            &:hover, &:focus {
              border: 1px solid var(--gray---4);
              background-color: var(--gray---5);
            }
          }
        }
      }
    }
  }
  .card-bottom {
    background: var(--white);
    box-shadow: var(--card---shadow);
    border-top: 1px solid var(--gray---5);
    height: 50px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    padding: 20px 12px;
    .card-bottom-button {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: flex-start;
      width: fit-content;
      height: 32px;
      border-radius: 4px;
      padding: 4px 6px;
      border: 1px solid transparent;
      cursor: pointer;
      &:hover,
      &:focus {
        border: 1px solid var(--brand-color---3);
      }
      .footer-icon {
        width: 16px;
        height: 16px;
        //margin: 13px 0px 13px 16px;
      }
      .card-text {
        font-family: "Poppins";
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 21px;
        align-items: flex-end;
        letter-spacing: 0.02em;
        color: var(--brand-color---5);
        flex: none;
        order: 1;
        flex-grow: 0;
      }
    }
  }
}


.open-modal {
  z-index: 5;
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  opacity: 1;
  transition: opacity 225ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.create-group-modal {
  z-index: 10;
  position: fixed;
  box-shadow: 1px 1px 20px rgba(195, 196, 207, 0.5);
  border-radius: 8px;
  padding: 40px;
  gap: 28px;
  width: 440px;
  //height: 296px;
  background: var(--white);

  .cancel {
    position: absolute;
    top: 18px;
    right: 17px;
    transform: translate(50%, -50%);
    cursor: pointer;
  }
  .modal-body {
    row-gap: 28px;
    display: flex;
    flex-direction: column;
    padding: 0;
    .title {
      .title-text {
        font-family: "Poppins";
        font-style: normal;
        font-weight: 600;
        font-size: 20px;
        line-height: 26px;
        color: var(--gray---1);
      }
    }
    .delete-icon {
      width: 40px;
      height: 40px;
      margin-left: auto;
      margin-right: auto;
    }
    .delete {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: flex-start;
      .delete-text {
        font-family: "Poppins";
        font-style: normal;
        font-weight: 600;
        font-size: 18px;
        line-height: 26px;
        color: var(--gray---1);
      }
    }
  }
  .explanation {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: var(--gray---2);
    text-align: center;
  }

  .input {
    display: flex;
    flex-direction: column;
    position: relative;
    width: 360px;
    height: auto;
    .not-valid-icon {
      z-index: 1;
      position: absolute;
      top: 13px;
      right: 12px;
      transform: translate(0, 0);
      width: 16px;
      height: 16px;
      cursor: pointer;
    }
    .not-valid-text {
      display: flex;
      align-items: center;
      letter-spacing: 0.4px;
      height: 18px;
      font-family: "Poppins";
      font-style: italic;
      font-weight: 400;
      font-size: 12px;
      line-height: 16px;
      color: var(--gray---1);
      margin-top: 4px;
      order: 2;
    }
    .input-text {
      width: 360px;
      height: 40px;
      padding: 8px 12px;
      border: 1px solid var(--gray---4);
      border-radius: 4px;
      flex: none;
      order: 1;
      align-self: stretch;
      flex-grow: 0;
      color: var(--gray---1);
      &.not-valid {
        border: 1px solid var(--red--alert);
      }
    }
  }
  .button {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 8px;
  }
  .button-cancel {
    padding: 9.5 12px;
    height: 40px;
    min-width: 100px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: 5px 12px;
    gap: 5px;
    font-family: "Poppins";
    font-weight: 500;
    font-size: 14px;
    line-height: 21px;
    color: var(--gray---1);
    border: 1px solid var(--gray---4);
    background: var(--gray---6);
    border-radius: 4px;
    text-transform: capitalize;
    &:hover,
    &:focus {
      color: var(--gray---1);
      border-radius: 4px;
      border: 1px solid var(--gray---4);
    }
  }
  .button-text {
    padding: 9.5 12px;
    height: 40px;
    min-width: 100px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: 5px 12px;
    gap: 5px;
    font-family: "Poppins";
    font-weight: 500;
    font-size: 14px;
    line-height: 21px;
    color: var(--white);
    /* Brand color - 4 */
    background: var(--brand-color---5);
    border-radius: 4px;
    text-transform: capitalize;
    border: none;
    &:hover,
    &:focus {
      color: var(--white) !important;
      background: var(--brand-color---1) !important;
      border-radius: 4px;
      border: none !important;
    }
    &:disabled {
      background: var(--brand-color---4);
      color: var(--brand-color---2);
      border: none !important;
    }
  }
}

.Toastify {
  .Toastify__toast-container {
    width: 400px;

    .Toastify__toast--success {
      background: #eafcfa;
      .Toastify__toast-icon {
        margin-right: 0.8em;
      }
      p {
        font-size: 14px;
        color: var(--primary----black);
        font-weight: 600;
        margin-bottom: -10px;
      }

      a {
        font-size: 14px;
        color: var(--primary----black);
        text-decoration: underline !important;
      }

      .Toastify__progress-bar--success {
        background: #18b19d;
      }
    }
  }
}
