.graph-modal {
    z-index: 99 !important;
}

.grid-topics {
    .saved-contacts-container {
        .MuiBox-root {
            margin-right: 1em;
            padding-right: 1em;
            border-right: 1px solid #EBEBF4;
        }

        .text {
            font-size: 14px;
            line-height: 21px;
            color: var(--subtitle----grey);
            font-weight: 500;
            margin-right: 0.4em;
        }

        label {
            .ant-checkbox-checked {
                .ant-checkbox-inner {
                    background-color: #228191 !important;
                    border-radius: 4px;
                    border: 0;

                    &:hover {
                        border: 0;
                        background-color: #228191 !important;
                        border-color: #85879C !important;
                    }
                }

                &::after {
                    border: 0;
                    border-color: #228191 !important;
                }
            }

            .ant-checkbox-inner {
                &:hover {
                    border-color: #85879C !important;
                }
            }
        }
    }
}

.card-vip-detail {
    .grid-chart-wrapper {
        margin: 20px;
        margin-top: 0px;
        height: 216px;
        position: relative;

        .grid-charts-amount,
        .grid-charts-doughnut {
            position: absolute;
            top: 0px;
            left: 0px;
            width: 100%;
            display: flex;
            justify-content: space-around;
            align-items: center;
            flex-wrap: wrap;

            span {
                font-style: normal;
                font-weight: 700;
                font-size: 24px;
                line-height: 36px;
                color: var(--secondary----grey);
            }

            
        }

        .grid-charts-doughnut {
            height: 100%;
        }

        .grid-charts-amount {
            height: 100%;
        }

        .grid-chart-first-date {
            float: left;
        }

        .grid-chart-last-date {
            float: right;
        }
    }

    .grid-charts {
        &:first-child {
            padding-right: 1em;
        }

        h6 {
            color: var(--subtitle----grey);
            padding: 1em;
            text-align: center;
            font-style: normal;
            font-weight: 400;
            font-size: 16px;
            line-height: 24px;
        }

        .MuiPaper-root {
            box-shadow: 0px 0px 4px rgba(88, 90, 106, 0.15) !important;
            border-radius: 4px;
        }

        .grid-chart-wrapper {
            margin: 20px;
            margin-top: 0px;
            height: 216px;
            position: relative;

            .grid-charts-amount,
            .grid-charts-doughnut {
                position: absolute;
                top: 0px;
                left: 0px;
                width: 100%;
                display: flex;
                justify-content: space-around;
                align-items: center;
                flex-wrap: wrap;

                span {
                    font-style: normal;
                    font-weight: 700;
                    font-size: 24px;
                    line-height: 36px;
                    color: #B8B9C6;
                }
            }

            .grid-charts-doughnut {
                height: 100%;
            }

            .grid-charts-amount {
                height: 100%;
            }

            .grid-charts-line {
                height: 95%;

                .grid-charts-amount-bottom-labels {
                    width: 100%;
                    font-style: normal;
                    font-weight: 400;
                    font-size: 12px;
                    line-height: 16px;
                }
            }

            .grid-chart-first-date {
                float: left;
            }

            .grid-chart-last-date {
                float: right;
            }
        }
    }

    .grid-charts-legend {
        margin-top: 2em;
        margin-right: auto;
        .legend-item-wrapper {
            font-style: normal;
            font-weight: 400;
            font-size: 12px;
            line-height: 16px;
            letter-spacing: 0.4px;
            color: #3F4253;
            display: flex;
            align-items: center;

            .legend-color {
                width: 12px;
                height: 12px;
                border-radius: 6px;
                display: inline-block;
                margin-right: 8px;
            }
            span {
                vertical-align: text-top;
                font-weight: 400 !important;
                font-size: 12px !important;
                line-height: 30px !important;
                color: var(--primary----grey) !important;
            }
        }
    }
}
.entityTitle {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 26px;
    height: 32px;
    display: flex;
    align-items: center;
    color: var(--gray---1);
    margin-bottom:  12px;
  }
  
