.invalid-format-modal {
    width: 100%;
    height: 100%;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    .invalid-format-card {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 440px;
        height: 319px;
        padding: 40px;
        border-radius: 8px;
        gap: 16px;

        box-shadow: 1px 1px 20px 0px rgba(195, 196, 207, 0.5);

        background-color: white;
    
        .invalid-format-card__icon {
            width: 40px;
            height: 40px;
        }
    
        .invalid-format-card__title {
            font-family: 'Poppins';
            font-size: 18px;
            font-weight: 600;
            line-height: 26px;
            letter-spacing: 0em;
            text-align: center;
        }
    
        .invalid-format-card__text {
            font-family: 'Poppins';
            font-size: 16px;
            font-weight: 500;
            line-height: 24px;
            letter-spacing: 0em;
            text-align: center;
        }
    
        .invalid-format-card__button {
            width: 107px;
            height: 40px;
            padding: 5px 16px;
            border-radius: 4px;
            display: flex;
            justify-content: center;
            align-items: center;
            gap: 5px;
            background-color: rgba(34, 129, 145, 1);

            border: none; // Removes the border
            outline: none; // Removes the focus outline
            
            .invalid-format-card__button-text{
                font-family: 'Poppins';
                color: white;
                font-size: 16px;
                font-weight: 500;
                line-height: 24px;
                letter-spacing: 0em;
                text-align: left;
            }
        }
    }
}