.entityList {
    display: flex;
    flex-direction: column;
    row-gap: 20px;

  .entityGroup {
    width: 100%;
    box-shadow: var(--card---shadow);

    .entityGroupTitle {
      font-family: "Poppins";
      font-style: normal;
      font-weight: 600;
      font-size: 12px;
      line-height: 18px;
      display: flex;
      align-items: center;
      letter-spacing: 0.02em;
      width: 100%;
      height: 40px;
      padding: 11px 20px;
      color: var(--black);
      border-bottom: 1px solid var(--gray---5);
    }
    .entityGroupContainer{
        padding: 12px 20px;
    }
  }
}
