.card-connection-detail {
    background-image: linear-gradient(180deg, rgba(199, 230, 235, 0.5) 0%, rgba(230, 244, 246, 0) 10%);

    .menutabs-container {
        width: 100%;
        margin-left: 1.5em;
        margin-bottom: 26px;

        .MuiTabs-root {
            padding: 0;
            background-color: #fff;
            border: 0;
            border-radius: 0;

            button {
                background-color: #fff;
                padding: 0.4em 1em;
            }

            .Mui-selected {
                color: #585A6A !important;
                font-weight: bold;
                border-radius: 4px;
                border-bottom: 4px solid #585A6A;
            }
        }
    }

    .scoring-container {
        background: #EAFCFA;
        border-radius: 4px;
        display: flex;
        flex-direction: column;
        width: 70%;
        text-align: center;
        padding: 3% 0;

        span {
            font-weight: 500;
            font-size: 14px;
            line-height: 23px;
            color: #85879C;
        }

        span:nth-child(2) {
            color: #18B19D;
            font-weight: 600;
            font-size: 20px;
            line-height: 26px;
        }
    }

    .grid-profile>div {
        gap: 4%;
    }

    .arrows-image {
        object-fit: none;
    }

    .grid-profile {
        .link {
            background: none;
            color: #252b42 !important;
            font-weight: 700;
            font-size: 24px;
            padding: 0;

            img {
                margin-right: 0.4em;
            }
        }

        .link:hover {
            text-decoration: underline !important;
        }

        .avatar-link {
            background: none;
            padding: 0;
        }

        .text-underline {
            text-decoration: underline !important;
        }
    }
}

.card-connection-table {
    background-color: #F9F9FA;
}

.ant-picker-header {

    .ant-picker-header-super-prev-btn,
    .ant-picker-header-prev-btn,
    .ant-picker-header-next-btn,
    .ant-picker-header-super-next-btn {
        color: #228191 !important;
    }
}

.ant-picker-cell-in-range {
    background-color: #E6F4F6;
    color: #3F4253;
}

.ant-picker-cell-range-start {
    background: #E6F4F6;

    .ant-picker-cell-inner {
        background: #228191 !important;
        color: #FFFFFF;
    }
}

.ant-picker-cell-range-end {
    background: #E6F4F6;

    .ant-picker-cell-inner {
        background: #228191 !important;
        color: #FFFFFF;
    }
}

.ant-cancel-button {
    margin-right: "0.5em";
    background: #EBEBF4 !important;
    border-radius: 4px !important;
    color: #3F4253 !important;
    border: "transparent";
    font-family: "Poppins";
    margin-right: 0.5em;
}

.ant-apply-button {
    background: #228191 !important;
    border-radius: 4px !important;
    color: #fff !important;
    padding: 5px 1.6em;
    border: transparent;
    font-size: 13px;
    font-family: "Poppins";

    &:disabled {
        background: #E6F4F6 !important;
        color: #7EC1CC !important;
        border: 0;
    }
}

.interactions-detail,
.card-connection-table {
    h1 {
        color: #85879C;
        font-weight: 700;
        font-size: 24px;
        line-height: 36px;
        margin: 2% 0;
    }

    .legend-item-wrapper {
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 16px;
        letter-spacing: 0.4px;
        color: #3F4253;
        display: inline-block;
        margin-right: 20px;
        margin-top: 19px;

        .legend-color {
            width: 12px;
            height: 12px;
            border-radius: 6px;
            display: inline-block;
            margin-right: 8px;
        }

        span {
            vertical-align: text-top;
        }
    }

    .table-title {
        font-style: normal;
        font-weight: 600;
        font-size: 18px;
        line-height: 26px;
        margin-bottom: 16px;
        color: #585A6A;

        small {
            font-style: normal;
            font-weight: 500;
            font-size: 12px;
            line-height: 18px;
            color: #85879C;
            margin-left: 1em;
        }
    }

    .MuiPaper-root {
        box-shadow: none !important;
    }

    .grid-chart-first-date {
        float: left;
    }

    .grid-chart-last-date {
        float: right;
    }

    .grid-charts-doughnut {
        height: 100%;
    }

    .grid-charts-amount {
        height: 100%;
    }

    .grid-charts {
        &:first-child {
            padding-right: 1em;
        }

        h6 {
            padding: 1em;
            text-align: center;
            font-style: normal;
            font-weight: 400;
            font-size: 16px;
            line-height: 24px;
        }

        .MuiPaper-root {
            box-shadow: 0px 0px 4px rgba(88, 90, 106, 0.15) !important;
            border-radius: 4px;
        }

        .grid-chart-wrapper {
            margin: 20px;
            margin-top: 0px;
            height: 216px;
            position: relative;

            .grid-charts-amount,
            .grid-charts-doughnut,
            .grid-charts-line {
                position: absolute;
                top: 0px;
                left: 0px;
                width: 100%;
                display: flex;
                justify-content: space-around;
                align-items: center;
                flex-wrap: wrap;

                span {
                    font-style: normal;
                    font-weight: 700;
                    font-size: 24px;
                    line-height: 36px;
                    color: #B8B9C6;
                }
            }

            .grid-charts-line {
                height: 95%;

                .grid-charts-amount-bottom-labels {
                    width: 100%;
                    font-style: normal;
                    font-weight: 400;
                    font-size: 12px;
                    line-height: 16px;
                }
            }

        }
    }

    .grid-table {
        .filters-container {
            .filters-title {
                padding: 7px 15px;
                border-bottom: 1px solid #EBEBF4;
                text-transform: uppercase;

                img {
                    margin-right: 0.5em;
                }
            }

            .filters-dropdowns-container {
                padding: 13px;

                .dropdown-title {
                    margin-bottom: 8px;
                }

                .filters-dropdown {
                    width: 100%;
                    height: 40px;
                    border-radius: 2px;
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    border: 1px solid #B8B9C6;

                    &.ant-dropdown-open {
                        border-color: #85879C !important;
                    }

                    span {
                        text-overflow: ellipsis;
                        text-align: left;
                        overflow: hidden;
                    }
                }

                .separator {
                    margin: 16px 0px;
                    background-color: #EBEBF4;
                    height: 1px;
                    width: 100%;
                }
            }
        }

        p {
            font-weight: 500;
            font-size: 15px;
            line-height: 23px;
            text-transform: uppercase;
        }

        .tableContainer {
            padding-left: 1em;
        }
    }

}

.ant-dropdown-menu[class] {
    border-radius: 2px;
}

.ant-dropdown-menu-title-content {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.connection-topics{
    height: min-content;
}
.grid-chart-wrapper {
    margin: 20px;
    margin-top: 0px;
    height: 216px;
    position: relative;

    .grid-charts-amount,
    .grid-charts-doughnut {
        position: absolute;
        top: 0px;
        left: 0px;
        width: 100%;
        display: flex;
        justify-content: space-around;
        align-items: center;
        flex-wrap: wrap;

        span {
            font-style: normal;
            font-weight: 700;
            font-size: 24px;
            line-height: 36px;
            color: var(--secondary----grey);
        }

        
    }

    .grid-charts-doughnut {
        height: 100%;
    }

    .grid-charts-amount {
        height: 100%;
    }

    .grid-chart-first-date {
        float: left;
    }

    .grid-chart-last-date {
        float: right;
    }
}

.grid-charts {
    &:first-child {
        padding-right: 1em;
    }

    h6 {
        color: var(--subtitle----grey);
        padding: 1em;
        text-align: center;
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
    }

    .MuiPaper-root {
        box-shadow: 0px 0px 4px rgba(88, 90, 106, 0.15) !important;
        border-radius: 4px;
    }

    .grid-chart-wrapper {
        margin: 20px;
        margin-top: 0px;
        height: 216px;
        position: relative;

        .grid-charts-amount,
        .grid-charts-doughnut {
            position: absolute;
            top: 0px;
            left: 0px;
            width: 100%;
            display: flex;
            justify-content: space-around;
            align-items: center;
            flex-wrap: wrap;

            span {
                font-style: normal;
                font-weight: 700;
                font-size: 24px;
                line-height: 36px;
                color: #B8B9C6;
            }
        }

        .grid-charts-doughnut {
            height: 100%;
        }

        .grid-charts-amount {
            height: 100%;
        }

        .grid-charts-line {
            height: 95%;

            .grid-charts-amount-bottom-labels {
                width: 100%;
                font-style: normal;
                font-weight: 400;
                font-size: 12px;
                line-height: 16px;
            }
        }

        .grid-chart-first-date {
            float: left;
        }

        .grid-chart-last-date {
            float: right;
        }
    }
}

.grid-charts-legend {
    margin-top: 2em;
    margin-right: auto;
    .legend-item-wrapper {
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 16px;
        letter-spacing: 0.4px;
        color: #3F4253;
        display: flex;
        align-items: center;

        .legend-color {
            width: 12px;
            height: 12px;
            border-radius: 6px;
            display: inline-block;
            margin-right: 8px;
        }
        span {
            vertical-align: text-top;
            font-weight: 400 !important;
            font-size: 12px !important;
            line-height: 30px !important;
            color: var(--primary----grey) !important;
        }
    }
}