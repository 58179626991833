.MuiPaper-root {
  border-bottom: 1px solid #ebebf4;
  margin-bottom: 0 !important;
  .navbar-link {
    padding-right: 3%;
    border-right: 1px solid #ebebf4;

    img {
      padding-right: 3%;
      max-width: 88px;
    }
  }

  .MuiBox-root {
    div {
      margin: 0 2%;
      a {
        text-decoration: none;
        font-weight: 500;
        color: #85879c;
      }
      p:focus,
      .active {
        border-bottom: 3px solid #585a6a;
        color: #585a6a;
      }
    }
  }

  .MuiAvatar-img {
    width: 30px;
    background-color: #ebebf4;
  }

  .navbar-menu p {
    font-weight: 500;
    font-size: 14px;
    line-height: 21px;
  }

  .navbar-menu a {
    font-weight: 500;
    font-size: 14px;
    line-height: 21px;
    color: #585a6a;
  }
}
