/* Color styles */
:root {
    --primary----yellow: rgba(197, 154, 22, 0.2);
    --primary----grey: #3F4253;
    --background----grey: #EBEBF4;
    --hover----grey: #F9F9FA;
    --primary----black: #252B42;
    --secondary----grey: #B8B9C6;
    --terciary---grey: #85879C;
    --subtitle----grey: #535564;
    --primary----blue: #228191;
    --focus----blue: #7EC1CC;
    --error----red: #EB5757;
    /* Color styles */
    --brand-color---5: rgba(34, 129, 145, 1);
    --brand-color---1: rgba(46, 160, 178, 1);
    --brand-color---2: rgba(126, 193, 204, 1);
    --brand-color---3: rgba(199, 230, 235, 1);
    --brand-color---4: rgba(230, 244, 246, 1);
    --brand-color---ligth-blue: rgba(225, 240, 255, 1);
    --brand-color---ligth-blue-2: rgba(189, 222, 255, 1);
    --black: rgba(37, 43, 66, 1);
    --gray---1: rgba(63, 66, 83, 1);
    --gray---2: rgba(83, 85, 100, 1);
    --gray---3: rgba(133, 135, 156, 1);
    --gray---4: rgba(184, 185, 198, 1);
    --gray---5: rgba(235, 235, 244, 1);
    --gray---6: rgba(249, 249, 250, 1);
    --white: rgba(255, 255, 255, 1);
    --link-color---1: rgba(57, 112, 213, 1);
    --link-color---2: rgba(67, 131, 250, 1);
    --acent---2: rgba(101, 174, 220, 1);
    --acent---3: rgba(122, 214, 202, 1);
    --acent---4: rgba(98, 211, 163, 1);
    --acent---5: rgba(24, 177, 157, 1);
    --acent---6: rgba(13, 96, 172, 1);
    --acent---7: rgba(130, 212, 128, 1);
    --acent-5---ligth: rgba(234, 252, 250, 1);
    --warning: rgba(250, 173, 20, 1);
    --v-i-p-color---1: rgba(169, 132, 19, 1);
    --v-i-p-color---2: rgba(250, 240, 209, 1);
    --contact-color---1: rgba(174, 37, 119, 1);
    --contact-color---2: rgba(251, 234, 244, 1);
    --red--alert: rgba(235, 87, 87, 1);
    --red-alert---hard: rgba(219, 0, 0, 1);
    --red-alert---ligth-2: rgba(255, 198, 198, 1);
    --red--alert---ligth: rgba(253, 237, 237, 1);

    /* Text-size styles */
    --h1: 24px;
    --h2: 20px;
    --h3: 18px;
    --s-t: 16px;
    --s-t---medium: 16px;
    --s-t---regular: 16px;
    --p1: 14px;
    --p2: 14px;
    --c1---medium: 12px;
    --c1---semibold: 12px;
    --c1---italic: 12px;
    --c2: 12px;
    --h3---regular: 18px;

    /* Effect styles */
    --card---shadow: 0px 0px 4px rgba(88, 90, 106, 0.15);
    --hover----shadow: 0px 4px 12px rgba(0, 0, 0, 0.12);
    --drop-menu---shadow: 0px 4px 10px rgba(91, 95, 122, 0.15);
    --star-saved-shadow: -2px 4px 6px rgba(37, 43, 66, 0.12);



}