.data-table {
    border-radius: 4px !important;

    thead {
        width: 100%;
        background-color: #EBEBF4;
        display: revert;
    }

    th {
        color: #585A6A;
    }

    tr:nth-child(2n){
        background-color: #F9F9FA;
    }
    .table-sorting-wrapper {
        display: inline-block;
        vertical-align: inherit;
        margin-left: 8px;
        cursor: pointer;
        img {
            display: block;
            &:first-child {
                margin-bottom: 3px;
            }
            &:hover {
                filter: brightness(80%);
                transition: all 0.1s ease;
            }
        }
    }
    .social-link {
        background: #EBEBF4;
        border-radius: 6px;
        width: 24px;
        height: 24px;
        display: flex;
        justify-content: space-between;
        flex-direction: row;
        align-items: center;
        text-align: center;
        a {
            width: 100%;
        }
    }

}

.table-pagination {
    margin-top: 1em;
    display: flex;
    align-items: center;
    justify-content: center;


    .Mui-selected {
        background: #7EC1CC !important;
        border: 0;
        color: #fff;
    }
}