.goback-button {
    font-size: 12px !important;
    color: #585A6A !important;
    font-weight: 500 !important;
    padding: 0 !important;
    min-width: auto !important;
    width: 40px;
    height: 40px;
    background: #F9F9FA !important;
    border: 1px solid #B8B9C6 !important;
}