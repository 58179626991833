.no-connections-contact {
    width: 100%;
    height: 50vh !important;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  
    .no-connections-contact__image {
        text-align: center;
        height: 80px;
        width: 80px;
    }
  
    .no-connections-contact__text {
        display: flex;
        flex-direction: column;
        align-items: center;
        text-align: center;
        margin-top: 20px;

        font-family: 'Poppins';
        font-size: 14px;
        font-weight: 500;
        line-height: 21px;
        color: rgba(83, 85, 100, 1)

    }
  }
  