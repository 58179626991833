.entityContainer {
  position: relative;
  display: flex;
  flex-direction: column;
  max-width: inherit;
  border-bottom: 1px solid var(--gray---5);
  background-color: var(--white);
  column-gap: 12px;
  cursor: pointer;
  .entityContainerTitle {
    height: 40px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    column-gap: 12px;
    padding: 12px 12px;
    .entityContainerIcon {
      width: 24px;
      height: 24px;
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      &.dict {
        background-color: var(--gray---2);
      }
      &.nlp {
        background-color: var(--gray---4);
      }
    }
    .entityContainerName {
      font-family: "Poppins";
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 21px;
      display: flex;
      align-items: center;
      color: var(--gray---1);
      margin-right: auto;
    }
    
  }
  .entityContainerSources[hidden=true] {
    height: 0;
  }
  .entityContainerSources {
    display: flex;
    flex-direction: column;
    padding:  12px 0;
    margin-left: 76px;
    row-gap: 12px;
    .entityContainerSource {
      display: flex;
      flex-direction: row;
      align-items: center;
      column-gap: 20px;
      .entityContainerSourceDate {
        font-family: "Poppins";
        font-style: normal;
        font-weight: 500;
        font-size: 12px;
        line-height: 18px;

        /* or 150% */
        display: flex;
        align-items: center;
        letter-spacing: 0.02em;

        color: var(--gray---1);
      }
      .entityContainerSourceIcon {
        width: 20px;
        height: 20px;
        border-radius: 4px;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: var(--white);
        border: 1px solid var(--gray---4);
      }
    }
  }
  &:hover,
    &.active,
    &:focus {
      background-color: var(--gray---6);
    }
}
