.entityContainerOptions {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 24px;
  height: 24px;
  border-radius: 4px;
  background-color: var(--white);
  margin-left: auto;
  border: 1px solid var(--gray---4);
  cursor: pointer;
  &:hover {
    background-color: var(--gray---4);
  }
}

.entityContainerDropdownBackground{
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    z-index: -1;
    background-color: transparent;
    opacity: 0.5;
}

.entityContainerDropdown {
  position: relative;
  //top: 40px;
  left: 0;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 12px;
  gap: 10px;
  box-shadow: var(--drop-menu---shadow);
  border-radius: 4px;
  z-index: 10;
  background-color: var(--white);
  .entityContainerDropdownItem {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 5px 12px;
    font-size: 14px;
    gap: 8px;
    height: 32px;
    background: #ffffff;
    border-radius: 2px;
    cursor: pointer;
    &:hover {
      background: var(--gray---5);
    }
  }
}
