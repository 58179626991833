.profile-container {
    .MuiOutlinedInput-root{        
        fieldset {
            color: #B8B9C6;
            border: 0 none;
        }
        
    }
    .MuiOutlinedInput-root.Mui-focused{
        border: 2px solid #2EA0B2 !important;
    }

    .MuiOutlinedInput-root.Mui-error{
        border: 2px solid #F44335 !important;
    }
}