.categories-container{
    .title {
        color: var(--primary----grey);
        font-family: 'Poppins';
        font-weight: 400;
        font-size: 18px;
    }
    .Mui-expanded {
        margin: 10px 0;
    }
    .MuiChip-root {
        margin: 0;
        margin-top: 10px;
        margin-right: 10px;
        border: 2px solid var(--background----grey);
        border-radius: 16px;
        .MuiChip-label {
            color: var(--primary----grey);
            font-family: 'Poppins';
            font-style: normal;
            font-weight: 600;
            font-size: 14px;
        }
        &:hover {
            border: 2px solid var(--background----grey);
            background-color: var(--background----grey);
        }
    }

    .ant-tooltip-inner {
        min-width: 8em !important;  
    }
}
