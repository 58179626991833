.card-container {
    padding-top: 3%;
    padding-bottom: 1%;
    align-items: center;
    align-content: center;

    .medal-image {
        position: absolute;
        top: 0px;
        right: 30%;
        border-radius: 50px;
    }

    .contact-image {
        position: absolute;
        top: 0;
        right: 30%;
        border-radius: 50px;
    }

    .MuiTypography-subtitle1 {
        color: var(--subtitle----grey);
        font-size: 14px;
        line-height: 21px;
        font-weight: 500;
    }

    .MuiTypography-h2 {
        color: #252B42;
        font-weight: 600;
        font-size: 18px;
        line-height: 26px;
    }

    a {
        background: #EBEBF4;
        padding: 0 0.4em;
        border-radius: 10px;
    }

    .MuiCardActions-spacing {
        min-height: 50px;

        a {
            border: 1px solid var(--secondary----grey);
            border-radius: 4px;
            background-color: var(--hover----grey);

            &:hover {
                background-color: var(--background----grey);
                img {
                    filter: invert(14%) sepia(35%) saturate(708%) hue-rotate(189deg) brightness(93%) contrast(91%);
                }
            }
        }
    }

    .link {
        background: transparent;
        width: 100%;
    }

    .contacts-number {
        display: block;
        margin: auto;
        width: 65%;
        background: #FAF0D1;
        border-radius: 15px;
        padding: 3px 0;
        font-weight: 600;
        color: var(--primary----grey);
    }

    .add-favourite {
        margin-right: 1%;
        border: 1px solid #EBEBF4;
        background: #fff;
        padding: 0; 
        z-index: 1;
        height: 30px;
        min-width: 40px !important; 
    }
    
}

.card-contact {
    box-shadow: 0px 0px 4px rgba(88, 90, 106, 0.15) !important;
    .avatar-image {
        margin: auto;
        border: 3px solid #AE2577;
        border-radius: 50px;
        background: #FBEAF4;
    }

    .contacts-number {
        background: #FBEAF4;
    }
}

.card-contact:hover {
    box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.12) !important;
}

.card-vip {
    box-shadow: 0px 0px 4px rgba(88, 90, 106, 0.15) !important; 

    .avatar-image {
        margin: auto;
        border: 3px solid #A98413;
        border-radius: 50px;
        background: #FAF0D1;
    }

    .contacts-number {
        background: #FAF0D1;
    }
}

.card-vip:hover {
    box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.12) !important;
}