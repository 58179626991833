.person-form {
    .vip-color {
        filter: invert(45%) sepia(78%) saturate(463%) hue-rotate(8deg) brightness(98%) contrast(94%);
    }

    .title {
        color: var(--primary----grey);
        font-weight: 600;
        font-size: 20px;
        line-height: 26px;
        margin-bottom: 1em !important;
    }

    .text {
        color: var(--primary----grey);
        font-weight: 500;
        font-size: 14px;
        line-height: 21px;
    }

    .input {
        background: white;
        color: var(--primary----grey);
        border: 1px solid var(--secondary----grey);
        gap: 10px;
        font-size: 16px;
        padding: 0 10px;
        width: 100%;
        border-radius: 4px;
        min-height: 35px;
    }

    .input-error {
        border: 1px solid var(--error----red);
    }

    .field-error {
        font-weight: 400;
        font-size: 12px;
        line-height: 16px;
        font-style: italic;
        color: var(--primary----grey);
    }

    .selector {
        color: var(--primary----grey);
        border: 0 !important;
        border: 1px solid var(--secondary----grey);
        font-family: "Poppins";
        font-weight: 500;
        font-size: 16px;
        gap: 10px;
        width: 100%;
        border-radius: 4px;

        > div:first-of-type {
            background: #F9F9FA;
        }

    }
    

    .button {
        background: var(--primary----blue);
        border-radius: 4px;
        color: white;
        padding: 5px 12px;
        gap: 5px;
        text-transform: capitalize;
    }

    .button:focus {
        color: #fff !important;
    }
}

.MuiBox-root:focus-visible {
    outline: none;
}